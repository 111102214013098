import React from "react"

import { starterPercentage } from "../utils/constants"
import { supportEmail } from "../utils/config"
import AppLink from "../components/AppLink"
import Layout from "../components/Layouts/Base"
import SEO from "../components/seo"

const MobileFeatureTables = ({
  txnFee,
  premiumSupport,
  responseTime,
  accountManager,
  integrationEngineer,
  couponCodes,
  gocardless,
  multipleUsers,
  customDomain,
}) => {
  return (
    <>
      <table className="mt-8 w-full">
        <caption className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">
          Additional charges
        </caption>
        <thead>
          <tr>
            <th className="sr-only" scope="col">
              Feature
            </th>
            <th className="sr-only" scope="col">
              Included
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          <tr className="border-t border-gray-200">
            <th
              className="py-5 px-4 text-sm font-normal text-gray-500 text-left"
              scope="row"
            >
              Per transaction fee
            </th>
            <td className="py-5 pr-4 text-right">{txnFee}</td>
          </tr>

          <tr className="border-t border-gray-200">
            <th
              className="py-5 px-4 text-sm font-normal text-gray-500 text-left"
              scope="row"
              colSpan={2}
            >
              Excludes card{" "}
              <a
                href="https://stripe.com/pricing"
                target="_blank"
                className="underline"
              >
                fees from Stripe
              </a>
              .
            </th>
          </tr>
        </tbody>
      </table>

      <table className="w-full">
        <caption className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">
          Support
        </caption>
        <thead>
          <tr>
            <th className="sr-only" scope="col">
              Feature
            </th>
            <th className="sr-only" scope="col">
              Included
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          <tr className="border-t border-gray-200">
            <th
              className="py-5 px-4 text-sm font-normal text-gray-500 text-left"
              scope="row"
            >
              Premium support
            </th>
            <td className="py-5 pr-4">
              {premiumSupport ? <Check /> : <Minus />}
            </td>
          </tr>

          <tr className="border-t border-gray-200">
            <th
              className="py-5 px-4 text-sm font-normal text-gray-500 text-left"
              scope="row"
            >
              Response time
            </th>
            <td className="py-5 pr-4 text-right">{responseTime}</td>
          </tr>

          <tr className="border-t border-gray-200">
            <th
              className="py-5 px-4 text-sm font-normal text-gray-500 text-left"
              scope="row"
            >
              Dedicated account manager
            </th>
            <td className="py-5 pr-4">
              {accountManager ? <Check /> : <Minus />}
            </td>
          </tr>

          <tr className="border-t border-gray-200">
            <th
              className="py-5 px-4 text-sm font-normal text-gray-500 text-left"
              scope="row"
            >
              Integration engineer
            </th>
            <td className="py-5 pr-4">
              {integrationEngineer ? <Check /> : <Minus />}
            </td>
          </tr>
        </tbody>
      </table>

      <table className="w-full">
        <caption className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">
          Features
        </caption>
        <thead>
          <tr>
            <th className="sr-only" scope="col">
              Feature
            </th>
            <th className="sr-only" scope="col">
              Included
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          <tr className="border-t border-gray-200">
            <th
              className="py-5 px-4 text-sm font-normal text-gray-500 text-left"
              scope="row"
            >
              Coupon codes
            </th>
            <td className="py-5 pr-4">{couponCodes ? <Check /> : <Minus />}</td>
          </tr>

          <tr className="border-t border-gray-200">
            <th
              className="py-5 px-4 text-sm font-normal text-gray-500 text-left"
              scope="row"
            >
              GoCardless integration
            </th>
            <td className="py-5 pr-4">{gocardless ? <Check /> : <Minus />}</td>
          </tr>

          <tr className="border-t border-gray-200">
            <th
              className="py-5 px-4 text-sm font-normal text-gray-500 text-left "
              scope="row"
            >
              Multiple users
            </th>
            <td className="py-5 pr-4 text-right">{multipleUsers()}</td>
          </tr>

          <tr className="border-t border-gray-200">
            <th
              className="py-5 px-4 text-sm font-normal text-gray-500 text-left"
              scope="row"
            >
              Use your own domain
            </th>
            <td className="py-5 pr-4">
              {customDomain ? <Check /> : <Minus />}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

const Check = () => (
  <>
    <svg
      className="ml-auto lg:ml-0 h-5 w-5 text-green-500"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
        clipRule="evenodd"
      />
    </svg>
    <span className="sr-only">Yes</span>
  </>
)

const Minus = () => (
  <>
    <svg
      className="ml-auto lg:ml-0 h-5 w-5 text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
        clipRule="evenodd"
      />
    </svg>
    <span className="sr-only">No</span>
  </>
)

const PricingPage = ({}) => {
  return (
    <Layout>
      <SEO
        title="Pricing"
        keywords={[
          `payments`,
          `online payments`,
          `card payments`,
          `recurring payments`,
          `subscriptions`,
          `recurring donations`,
          `donations`,
          `stripe`,
          `stripe billing`,
          `no-code stripe`,
          `no-code payments`,
          `stripe recurring payments`,
        ]}
      />
      <div className="max-w-5xl mx-auto px-6 py-10 lg:pb-16">
        <h1 className="text-2xl font-display text-black text-center font-bold leading-8 md:text-6xl sm:leading-tight">
          Pricing
        </h1>
      </div>

      <div className="bg-white">
        <div className="max-w-5xl mx-auto bg-white pb-16 sm:pb-24 px-6">
          {/* xs to lg */}
          <div className="max-w-2xl mx-auto lg:hidden">
            <div className="px-4">
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                Starter
              </h2>
              <p className="mt-4">
                <span className="text-4xl font-extrabold text-gray-900">
                  $0
                </span>
                <span className="text-base font-medium text-gray-500">/mo</span>
              </p>
              <p className="mt-4 text-sm text-gray-500">
                No commitments, risk-free to try it out!
              </p>
              <AppLink
                path="/signups/new"
                params={{ plan: "starter" }}
                className="mt-6 block border border-payhere rounded-md bg-payhere w-full py-2 text-sm font-semibold text-white text-center hover:bg-blue-500"
              >
                Try it now
              </AppLink>
            </div>

            <MobileFeatureTables
              txnFee={starterPercentage}
              premiumSupport={false}
              responseTime="72 hours"
              accountManager={false}
              integrationEngineer={false}
              couponCodes={true}
              gocardless={false}
              multipleUsers={() => <Minus />}
              customDomain={false}
            />

            <div className="border-t border-gray-200 px-4 pt-5">
              <AppLink
                path="/signups/new"
                params={{ plan: "starter" }}
                className="block border border-payhere rounded-md bg-payhere w-full py-2 text-sm font-semibold text-white text-center hover:bg-blue-500"
              >
                Try it now
              </AppLink>
            </div>

            <div className="px-4 mt-16">
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                Business
              </h2>
              <p className="mt-4">
                <span className="text-4xl font-extrabold text-gray-900">
                  $55
                </span>
                <span className="text-base font-medium text-gray-500">/mo</span>
              </p>
              <p className="mt-4 text-sm text-gray-500">
                Best for most established businesses.
              </p>
              <a
                href={supportEmail}
                target="_blank"
                className="mt-6 block border border-gray-800 rounded-md bg-gray-800 w-full py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
              >
                Contact us
              </a>
            </div>

            <MobileFeatureTables
              txnFee="1%"
              premiumSupport={true}
              responseTime="24 hours"
              accountManager={false}
              integrationEngineer={false}
              couponCodes={true}
              gocardless={true}
              multipleUsers={() => <span className="block text-right">2</span>}
              customDomain={false}
            />

            <div className="border-t border-gray-200 px-4 pt-5">
              <a
                href={supportEmail}
                target="_blank"
                className="block border border-gray-800 rounded-md bg-gray-800 w-full py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
              >
                Contact us
              </a>
            </div>

            <div className="px-4 mt-16">
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                Enterprise
              </h2>
              <p className="mt-4">
                <span className="text-base font-medium text-gray-500">
                  Tailored pricing
                </span>
              </p>
              <p className="mt-4 text-sm text-gray-500">
                For large organisations with unique needs or high payments
                volume.
              </p>
              <a
                href={supportEmail}
                target="_blank"
                className="mt-6 block border border-gray-800 rounded-md bg-gray-800 w-full py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
              >
                Contact us
              </a>
            </div>

            <MobileFeatureTables
              txnFee="Negotiable"
              premiumSupport={true}
              responseTime="Negotiable"
              accountManager={true}
              integrationEngineer={true}
              couponCodes={true}
              gocardless={true}
              multipleUsers={() => <span>10+</span>}
              customDomain={true}
            />

            <div className="border-t border-gray-200 px-4 pt-5">
              <a
                href={supportEmail}
                target="_blank"
                className="block border border-gray-800 rounded-md bg-gray-800 w-full py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
              >
                Contact us
              </a>
            </div>
          </div>

          {/* lg+ */}
          <div className="hidden lg:block">
            <table className="w-full h-px table-fixed">
              <caption className="sr-only">Pricing plan comparison</caption>
              <thead>
                <tr>
                  <th
                    className="pb-4 px-6 text-sm font-medium text-gray-900 text-left"
                    scope="col"
                  >
                    <span className="sr-only">Feature by</span>
                    <span>Plans</span>
                  </th>

                  <th
                    className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
                    scope="col"
                  >
                    Starter
                  </th>

                  <th
                    className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
                    scope="col"
                  >
                    Business
                  </th>

                  <th
                    className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
                    scope="col"
                  >
                    Enterprise
                  </th>
                </tr>
              </thead>
              <tbody className="border-t border-gray-200 divide-y divide-gray-200">
                <tr>
                  <th
                    className="py-8 px-6 text-sm font-medium text-gray-900 text-left align-top"
                    scope="row"
                  >
                    Pricing
                  </th>

                  <td className="h-full py-8 px-6 align-top">
                    <div className="relative h-full table">
                      <p>
                        <span className="text-4xl font-extrabold text-gray-900">
                          $0
                        </span>
                        <span className="text-base font-medium text-gray-500">
                          /mo
                        </span>
                      </p>
                      <p className="mt-4 mb-16 text-sm text-gray-500">
                        No commitments, risk-free to try it out!
                      </p>
                      <AppLink
                        path="/signups/new"
                        params={{ plan: "starter" }}
                        className="absolute bottom-0 flex-grow block w-full bg-payhere border border-payhere rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-blue-500 hover:border-blue-500"
                      >
                        Try it now
                      </AppLink>
                    </div>
                  </td>

                  <td className="h-full py-8 px-6 align-top">
                    <div className="relative h-full table">
                      <p>
                        <span className="text-4xl font-extrabold text-gray-900">
                          $55
                        </span>
                        <span className="text-base font-medium text-gray-500">
                          /mo
                        </span>
                      </p>
                      <p className="mt-4 mb-16 text-sm text-gray-500">
                        Best for most established businesses.
                      </p>
                      <a
                        href={supportEmail}
                        target="_blank"
                        className="absolute bottom-0 flex-grow block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                      >
                        Contact us
                      </a>
                    </div>
                  </td>

                  <td className="h-full py-8 px-6 align-top">
                    <div className="relative h-full table">
                      <p>
                        <span className="text-4xl font-extrabold text-gray-300">
                          *
                        </span>
                        <span className="text-base font-medium text-gray-500">
                          Tailored pricing
                        </span>
                      </p>
                      <p className="mt-4 mb-16 text-sm text-gray-500">
                        For large organisations with unique needs.
                      </p>
                      <a
                        href={supportEmail}
                        target="_blank"
                        className="absolute bottom-0 flex-grow block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                      >
                        Contact us
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th
                    className="bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left"
                    colspan="4"
                    scope="colgroup"
                  >
                    Additional charges
                  </th>
                </tr>
                <tr>
                  <th
                    className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                    scope="row"
                  >
                    Per transaction fee
                  </th>
                  <td className="py-5 px-6">{starterPercentage}</td>
                  <td className="py-5 px-6">1%</td>
                  <td className="py-5 px-6">Negotiable</td>
                </tr>
                <tr>
                  <th
                    className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                    scope="row"
                  >
                    Card processing
                  </th>
                  <td className="py-5 px-6">
                    Excludes{" "}
                    <a
                      href="https://stripe.com/pricing"
                      target="_blank"
                      className="underline"
                    >
                      Stripe fees
                    </a>
                  </td>
                  <td className="py-5 px-6">
                    Excludes{" "}
                    <a
                      href="https://stripe.com/pricing"
                      target="_blank"
                      className="underline"
                    >
                      Stripe fees
                    </a>
                  </td>
                  <td className="py-5 px-6">
                    Excludes{" "}
                    <a
                      href="https://stripe.com/pricing"
                      target="_blank"
                      className="underline"
                    >
                      Stripe fees
                    </a>
                  </td>
                </tr>

                <tr>
                  <th
                    className="bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left"
                    colspan="4"
                    scope="colgroup"
                  >
                    Support
                  </th>
                </tr>

                <tr>
                  <th
                    className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                    scope="row"
                  >
                    Premium support
                  </th>
                  <td className="py-5 px-6">
                    <Minus />
                  </td>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                </tr>

                <tr>
                  <th
                    className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                    scope="row"
                  >
                    Response time
                  </th>
                  <td className="py-5 px-6">72 hours</td>
                  <td className="py-5 px-6">24 hours</td>
                  <td className="py-5 px-6">Negotiable</td>
                </tr>

                <tr>
                  <th
                    className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                    scope="row"
                  >
                    Dedicated account manager
                  </th>
                  <td className="py-5 px-6">
                    <Minus />
                  </td>
                  <td className="py-5 px-6">
                    <Minus />
                  </td>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                </tr>

                <tr>
                  <th
                    className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                    scope="row"
                  >
                    Integration engineer
                  </th>
                  <td className="py-5 px-6">
                    <Minus />
                  </td>
                  <td className="py-5 px-6">
                    <Minus />
                  </td>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                </tr>

                <tr>
                  <th
                    className="bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left"
                    colspan="4"
                    scope="colgroup"
                  >
                    Features
                  </th>
                </tr>

                <tr>
                  <th
                    className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                    scope="row"
                  >
                    Payment links
                  </th>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                </tr>

                <tr>
                  <th
                    className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                    scope="row"
                  >
                    Storefront
                  </th>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                </tr>

                <tr>
                  <th
                    className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                    scope="row"
                  >
                    Recurring payments
                  </th>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                </tr>

                <tr>
                  <th
                    className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                    scope="row"
                  >
                    Coupon codes
                  </th>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                </tr>

                <tr>
                  <th
                    className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                    scope="row"
                  >
                    GoCardless integration
                  </th>
                  <td className="py-5 px-6">
                    <Minus />
                  </td>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                </tr>

                <tr>
                  <th
                    className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                    scope="row"
                  >
                    Multiple users
                  </th>
                  <td className="py-5 px-6">
                    <Minus />
                  </td>
                  <td className="py-5 px-6">
                    <span className="block text-sm text-gray-700">
                      2 users included
                    </span>
                    <span className="block text-xs text-gray-500">
                      then $10/user/mo
                    </span>
                  </td>
                  <td className="py-5 px-6">
                    <span className="block text-sm text-gray-700">
                      Unlimited users
                    </span>
                  </td>
                </tr>

                <tr>
                  <th
                    className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                    scope="row"
                  >
                    Use your own domain
                  </th>
                  <td className="py-5 px-6">
                    <Minus />
                  </td>
                  <td className="py-5 px-6">
                    <Minus />
                  </td>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                </tr>

                <tr>
                  <th
                    className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                    scope="row"
                  >
                    Customised payment links
                  </th>
                  <td className="py-5 px-6">
                    <Minus />
                  </td>
                  <td className="py-5 px-6">
                    <Minus />
                  </td>
                  <td className="py-5 px-6">
                    <Check />
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr className="border-t border-gray-200">
                  <th className="sr-only" scope="row">
                    Choose your plan
                  </th>

                  <td className="pt-5 px-6">
                    <AppLink
                      path="/signups/new"
                      params={{ plan: "starter" }}
                      className="block w-full bg-payhere border border-payhere rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-blue-500 hover:border-blue-500"
                    >
                      Try it now
                    </AppLink>
                  </td>

                  <td className="pt-5 px-6">
                    <a
                      href={supportEmail}
                      target="_blank"
                      className="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                    >
                      Contact us
                    </a>
                  </td>

                  <td className="pt-5 px-6">
                    <a
                      href={supportEmail}
                      target="_blank"
                      className="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                    >
                      Contact us
                    </a>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PricingPage
